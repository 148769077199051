function Tutorial() {
    return (
        <div className="row tutorial">
            <div className="col-0 col-md-1 col-lg-2"></div>
            <div className="col-12 col-md-10 col-lg-8">
                <hr />
                <h1>Gym Owner Setup</h1>
                <hr />
                <div className="row setup-row">
                    <div className="col-12 col-lg-5">
                        <h2>Account Creation</h2>
                        <p>Gym owners can create an account on the gymvision website at <a href="https://gymvisionsite.azurewebsites.net/Identity/Account/Register">this link</a> by filling in their information or using their google account.</p>
                        <p>Continue through the account creation steps and login to your account on the website.</p>
                    </div>
                    <div className="col-12 col-lg-7 image-col">
                        <img className="tutorial-image" alt="Gymvision Registration page." src="https://gymvisionstorage.blob.core.windows.net/images/web-login.png"></img>
                    </div>
                </div>
                <hr />
                <div className="row setup-row">
                    <div className="col-12 col-lg-5">
                        <h2>Create Areas</h2>
                        <p>Once you have logged in to your account, you will need to add your gym areas that you want tracked. Head to the "Area Setup" page via the dropdown menu or at <a href="https://gymvisionsite.azurewebsites.net/Areas">this link</a>.</p>
                        <p>Select "Create New Area" and fill in the details about your area. Repeat for each area you plan to track within your gym.</p>
                    </div>
                    <div className="col-12 col-lg-7 image-col">
                        <img className="tutorial-image" alt="Gymvision Registration page." src="https://gymvisionstorage.blob.core.windows.net/images/web-area.png"></img>
                    </div>
                </div>
                <hr />
                <div className="row setup-row">
                    <div className="col-12 col-lg-5">
                        <h2>Link Pi to Area</h2>
                        <p>Next each raspberry pi device will need to be linked to an area. Head to the "Pi to Area Maps" page via the dropdown menu or at <a href="https://gymvisionsite.azurewebsites.net/PiAreaMaps">this link</a>.</p>
                        <p>Select "Create New Mapping", then select an area from the dropdown and enter what you want to be the corresponding PiID provided with your raspberry pi's. Repeat for each area you plan to track within your gym.</p>
                    </div>
                    <div className="col-12 col-lg-7 image-col">
                        <img className="tutorial-image" alt="Gymvision Registration page." src="https://gymvisionstorage.blob.core.windows.net/images/web-mapping.png"></img>
                    </div>
                </div>
                <hr />
                <div className="row setup-row">
                    <div className="col-12 col-lg-5">
                        <h2>View Data</h2>
                        <p>Now you are all set up and the pi's will start collecting data. You can view data on the website in a few places.</p>
                        <p>First, the "Overview" page gives brief information on what is currently happening in the gym.</p>
                        <p>Next, the "Counts" page will give detailed information about each area, including historical data.</p>
                        <p>Finally, the "Equipment" page will give detailed information about the equipment detected throughout the gym, including historical data.</p>
                    </div>
                    <div className="col-12 col-lg-7 image-col">
                        <img className="tutorial-image" alt="Gymvision Registration page." src="https://gymvisionstorage.blob.core.windows.net/images/web-multidata.png"></img>
                    </div>
                </div>
                <hr />
                <div className="row setup-row">
                    <div className="col-12 col-lg-5">
                        <h2>Setup Web View</h2>
                        <p>GymVision offers owner a unique way to display information to customers. First, head to the "Web Plugin" page via the dropdown or at <a href="https://gymvisionsite.azurewebsites.net/WebPlugin">this link</a>.</p>
                        <p>Next, use the toggle switches to choose which elements you want displayed to customers. Once you have made your selections, click "Get your display link!" and copy the provided link.</p>
                        <p>This link goes to a seperate site corresponding to the selections you just made. Owners can send customers to this site, host this site on televisions in the gym, or embed the page in their own website.</p>
                        <p>Selection changes will automatically be updated at the same url upon page refresh.</p>
                    </div>
                    <div className="col-12 col-lg-7 image-col">
                        <img className="tutorial-image" alt="Gymvision Registration page." src="https://gymvisionstorage.blob.core.windows.net/images/web-customizer.png"></img>
                    </div>
                </div>
                <hr />
                <div className="row setup-row">
                    <div className="col-12 col-lg-5">
                        <h2>Upload Historical Data</h2>
                        <p>GymVision offers a secure and in depth API where gym owners can upload as well as retrieve their data. This allows owners with existing historical statistics to upload them to the site in order to compare with results tracked via GymVision.</p>
                        <p>Full API documentation can be found on the website "API" page via the dropdown or at <a href="https://gymvisionsite.azurewebsites.net/WebPlugin/Swagger">this link</a>.</p>
                    </div>
                    <div className="col-12 col-lg-7 image-col">
                        <img className="tutorial-image" alt="Gymvision Registration page." src="https://gymvisionstorage.blob.core.windows.net/images/web-api.png"></img>
                    </div>
                </div>
                <hr />
                <h1>Infrastructure Setup</h1>
                <hr />
                <p>Detailed instructios on hosting and setting up the GymVision solution can be found <a href="https://capstone-cs.eng.utah.edu/groups/gymvision/-/wikis/ReadMe">HERE</a></p>
            </div>
            <div className="col-0 col-md-1 col-lg-2"></div>
        </div>
    );
}

export default Tutorial;