import FeatureBox from "./FeatureBox";
import { FEATURES } from "./FeatureBox";
import { useState } from "react";

function Home() {

    const [feature, setFeature] = useState(FEATURES[0]);
    function ChangeFeature(e) {
        var featNum = e.target.id.split("-")[1];
        setFeature(FEATURES[featNum]);
    }

    return (
        <div>
            <div className="seperator logo-head">
                <img alt="Gymvision logo." className="logo-img" src="https://gymvisionstorage.blob.core.windows.net/images/gymvisionLogo.png"/>
                <h1 className="logo-text">GymVision</h1>
            </div>
            <div className="seperator justify-content-center align-items-center">
                <div className="col-0 col-lg-1"></div>
                <div className="col-12 col-lg-6">
                    <h1>The Gymvision Solution</h1>
                    <p>In every gym we see common frustrations that effect customer experience:</p>
                    <ul>
                        <li>Overcrowding</li>
                        <li>Understaffing</li>
                        <li>Overused Equipment</li>
                        <li>Underused Equipment</li>
                    </ul>
                    <p>As a gym owner, it is very difficult to address these issues without a data driven solution. Gymvision offers this solution.
                    Using computer vision to detect people and equipment, Gymvision is able to provide gym owners and managers with detailed and
                    important information regarding their gym.</p>
                </div>
                <div className="col-12 col-lg-4">
                    <img alt="Detections of equipment gif." className="detections-gif" src="https://gymvisionstorage.blob.core.windows.net/images/detections-gif.gif"/>
                </div>
                <div className="col-0 col-lg-1"></div>
            </div>
            <div className="feature-section">
                <div className="feature-selector">
                    {FEATURES.map((item, i) => (
                        (feature.id === item.id ?
                            <p id={item.id} className="active-feature" key={i} onClick={ChangeFeature}>{item.name}</p>
                            :
                            <p id={item.id} key={i} onClick={ChangeFeature}>{item.name}</p>
                        )
                    ))}
                </div>
                <FeatureBox feature={feature}></FeatureBox>
            </div>
        </div>
    );
}

export default Home;