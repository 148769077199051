import TeamMemberBox from "./TeamMember";
import { TEAMMEMBERS } from "./TeamMember";

function Team() {
    return (
        <div>
            <div className="team-head-bg"></div>
            <div className="seperator team-head">
                <h1 className="team-text">The Team</h1>
            </div>
            <div className="row w-100 team-row">
                <div className="col-0 col-lg-1 col-xl-2"></div>
                <div className="col-12 col-lg-10 col-xl-8">
                    <div className="team-members">
                        {TEAMMEMBERS.map((item, i) => (
                            <TeamMemberBox id={item.id} key={i}></TeamMemberBox>
                        ))}
                    </div>
                </div>
                <div className="col-0 col-lg-1 col-xl-2"></div>
            </div>
        </div>
    );
}

export default Team;