function FeatureBox(_feature) {
    var feature = _feature.feature;
    return (
        <div className="feature-sizing">
            <div className="feature-box row">
                <div className="col-12 col-lg-5 feature-text">
                    <h1 className="text-center">{feature.name}</h1>
                    <p>{feature.description}</p>
                </div>
                <div className="col-12 col-lg-7">
                    <img alt="Feature from website." className="feature-image" src={"https://gymvisionstorage.blob.core.windows.net/images/" + feature.image}/>
                </div>
           </div>
        </div>
    );
}

export class Feature {
    id;
    name;
    description;
    image;

    constructor(initializer) {
        if (!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.description) this.description = initializer.description;
        if (initializer.image) this.image = initializer.image;
    }
}

export const FEATURES = [
    new Feature({
        id: "feat-0",
        name: "Crowd Levels",
        description: "Gymvision offers the ability to view crowd levels in the gym in real time. Gym owners can also see predicted crowd levels based on their historical data. Owners are also able to view their historical data from the last week, month, or year.",
        image: "web-crowd.png"
    }),
    new Feature({
        id: "feat-1",
        name: "Equipment Usage",
        description: "Gymvision detects equipment in each area of the gym and provides usage levels for each equipment type in real time. Owners are also able to view their data from the last week, month, or year. This allows owners to make more informed decisions as to which types of equipment to buy and sell.",
        image: "web-equipment.png"
    }),
    new Feature({
        id: "feat-2",
        name: "Heatmap",
        description: "Gymvision provides heatmaps detailing area layouts and crowd trends within each area. This allows gyms owners to understand how customers are moving within the space as well as test and analyze different equipment layouts.",
        image: "web-heatmap.png"
    }),
    new Feature({
        id: "feat-3",
        name: "Privacy",
        description: "While many may have security and privacy concerns with utilization of cameras within gyms, the Gymvision solution anonymizes all data and handles all image processing on each device. The only data sent out from devices are bounding boxes of objects detected and no images are saved or stored.",
        image: "web-privacy.png"
    }),
    new Feature({
        id: "feat-4",
        name: "Customizable Display",
        description: "Through the Gymvision website, owners are able to customize a display to be visible to customers. This allows customers to view real time crowd levels and currently full equipment. This display can be embedded into an existing gym webpage or displayed on a screen via the webpage.",
        image: "web-customizer.png"
    }),
    new Feature({
        id: "feat-5",
        name: "Secured API",
        description: "Gymvision offers a powerful and well documented RESTful API. This allows owners to retrieve all of the collected data from their gym as well as upload their own historical data when initially implementing Gymvision. The API is secured with an API key available only to logged in gym owners.",
        image: "web-api.png"
    })
]

export default FeatureBox;