function TeamMemberBox(id) {
    const teamMember = TEAMMEMBERS[id.id.split("-")[1]];
    return (
        <div>
            <div className="flip-card">
                <div className="flip-card-inner">
                    <div className="flip-card-front">
                        <img src={"https://gymvisionstorage.blob.core.windows.net/images/" + teamMember.image} alt="Avatar" className="flip-card-image" />
                    </div>
                    <div className="flip-card-back">
                        <h1>{teamMember.name}</h1>
                        <p>{teamMember.description}</p>
                    </div>
                </div>
            </div>
            <div className="contact-info">
                <p>{teamMember.email}</p>
                <a target="_blank" rel="noreferrer" href={"https://www.linkedin.com/in/" + teamMember.linkedin}><i className="fa-brands fa-linkedin"></i></a>
            </div>
        </div>
    );
}

export default TeamMemberBox;

export class TeamMember {
    id;
    name;
    description;
    image;
    email;
    linkedin;

    constructor(initializer) {
        if (!initializer) return;
        if (initializer.id) this.id = initializer.id;
        if (initializer.name) this.name = initializer.name;
        if (initializer.description) this.description = initializer.description;
        if (initializer.image) this.image = initializer.image;
        if (initializer.email) this.email = initializer.email;
        if (initializer.linkedin) this.linkedin = initializer.linkedin;
    }
}

export const TEAMMEMBERS = [
    new TeamMember({
        id: "member-0",
        name: "Cristina Novac",
        description: "I'm Cristina Novac and I'll be graduating with a Bachelor's in Computer Science. I am passionate about human centered computing particularly as it pertains to the influence that the design of tools and technologies have on a user's behavior. I was involved in GymVision due to my interest in computer vision and it's potential applications, but I contributed more on the frontend web development side of things. I enjoy helping others learn so I have worked as a Teaching Assistant throughout my time in college and I look forward to finding employment at a company that encourages collaboration between people with a variety of skill sets. When I'm not at my computer, I spend my time rock climbing and working towards my personal trainer and yoga teacher certifications.",
        image: "Cristina.jpg",
        email: "cnovac01@gmail.com",
        linkedin: "cristina-novac"
    }),
    new TeamMember({
        id: "member-1",
        name: "Ethan Davies",
        description: "I am Ethan Davies and I am graudating with a bachelor's in computer science. I currently work as a software engineer at Foursight Capital doing full stack development. I have built and maintain my own personal website. I have also created several projects using C# and the .NET framework, including GymVision. I am generally more interested in backend design and working with data or computer networking. Outside of computer science I enjoy camping, snowboarding, kayaking, and hiking.",
        image: "Ethan.jpg",
        email: "ethandavies8@gmail.com",
        linkedin: "ethan-davies1"
    }),
    new TeamMember({
        id: "member-2",
        name: "Jesse Andringa",
        description: "I am Jesse Andringa, and I am graduating with a bachelor's in computer science and a minor in philosolphy. I was a professional Freestyle Mogul Skier on the US Ski Team for 4 years, but decided to finish my degree at Utah. I currently work at SlamBox doing computer vision and full stack development. I am interested in ML, computer vision, and anything AI. I have built out many applications that utilize computer vision, NLP, langchain, etc. including our senior project Gym Vision. My interests outside of computer science are skiing, mountain biking, hockey, and camping.",
        image: "Jesse.jpeg",
        email: "jess.andringa@gmail.com",
        linkedin: "jesse-andringa-200208241"
    }),
    new TeamMember({
        id: "member-3",
        name: "Logan Holmes",
        description: "I am Logan Holmes and I am graduating in spring of 2024 with a bachelor's in computer science and a mathematics minor. I currently work as a Software Engineer for The Kroger Co. In my time at the University of Utah, I have solidified my skills in full stack development including hosting on Azure and working with APIs. I am also very interested in computer vision and deep learning and I have developed multiple apps using knowledge gained from coursework and independent study. Outside of computer science, I enjoy running and have completed the Boston Marathon as well as a 100 mile race in the last year.",
        image: "Logan.JPG",
        email: "loganholmes33@gmail.com",
        linkedin: "realloganholmes"
    })
]